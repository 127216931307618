<template>
  <div>
    <el-card>
      <alter-form :watchImageVisible="true" ref="alterForm" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength"></alter-form>
    </el-card>
  </div>
</template>

<script>
import onlineCouponEdit from './index.js'
export default onlineCouponEdit
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
